<template>
<div if="Users">

<div>
  <div class="pa-5 secondary elevation-5 bg-accent-card">
    <p class="mt-5 display-1">Users
      <v-btn color="accent " style="margin-top: -15px" @click="$router.push('/adduser')" fab dark absolute right >
        <v-icon> add </v-icon>
      </v-btn>
    </p>
  </div>
</div>  

<datatablevue :tableData="tableData"></datatablevue>


</div>
</template>

<script>
const datatablevue = () => import(/* webpackChunkName: "datatablevue" */ '../../components/shared/dataTable');


// scripts
export default {
    
    name: 'Users',

    created(){this.$store.dispatch('fetchUserData');},

    components: {
        datatablevue
    },

    data(){return{
        
        // table headers
        headers: [ 
            { text: 'Name', value: 'displayName' }, 
            { text: 'Email', value: 'email' },
            { text: 'Phone', value: 'phone' },
        ],

    }},

    computed: {

      GetAllUsers(){
        return this.$store.getters.GetAllUsers;
      },

      tableData(){
        return {'headers': this.headers, 'Data': this.GetAllUsers}
      },

    },

}
</script>

<style lang="scss" scoped>

</style>